@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url("https://cdn.jsdelivr.net/npm/galmuri/dist/galmuri.css");

body,html {
  overflow: hidden;
}

* {
  margin: 0;
  font-size: 10px;
  padding: 0;
  -ms-overflow-style: none; 
  scrollbar-width: none;
  font-family: Pretendard;
  &::-webkit-scrollbar {
    display: none;
  }
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}